/* Base styling for DatePicker input */
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: 40px;
}

.react-datepicker__input-container input {
    padding: 10px 14px;
    font-size: 16px;
    color: #100101;
    background: linear-gradient(135deg, #ffffff, #eeeff0); 
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    outline: none;
}

.react-datepicker__input-container input:focus {
    transform: translateY(-2px); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); 
}

/* Custom DatePicker pop-up */
.react-datepicker {
    font-size: 14px;
    border: none;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    background: #ffffff; 
    overflow: hidden;
    animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.react-datepicker__header {
    background: linear-gradient(135deg, #ffffff, #39a0cc); 
    border-bottom: none;
    padding: 16px;
    color: #ffffff;
    text-align: center;
}

.react-datepicker__current-month {
    font-size: 18px;
    font-weight: bold;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #4a5568; 
    margin: 0.3rem;
    width: 2.5rem;
    line-height: 2.5rem;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.react-datepicker__day:hover,
.react-datepicker__day:focus {
    background-color: #764ba2; 
    color: #ffffff; 
    cursor: pointer;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected {
    background-color: #667eea; 
    color: #ffffff; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}

/* Time picker customization */
.react-datepicker__time-container {
    background: #f7fafc; 
    border-left: none;
}

.react-datepicker__time-list-item {
    height: 40px;
    padding: 10px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.react-datepicker__time-list-item:hover,
.react-datepicker__time-list-item--selected {
    background-color: #667eea; 
    color: #ffffff; 
}

/* Scrollbar styling */
.react-datepicker__time-list {
    scrollbar-width: thin;
    scrollbar-color: #667eea #f7fafc;
}

.react-datepicker__time-list::-webkit-scrollbar {
    width: 8px;
}

.react-datepicker__time-list::-webkit-scrollbar-thumb {
    background-color: #667eea;
    border-radius: 8px;
}

.react-datepicker__time-list::-webkit-scrollbar-track {
    background-color: #f7fafc;
}
