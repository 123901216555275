/* Style for the modal overlay */
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

/* Style for the modal content */
.ReactModal__Content {
  background-color: white !important; 
  padding: 2rem; 
  border-radius: 12px; 
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.1); 
  max-width: 500px;
  max-height:600px;
  width: 90%;
  margin: 40px 465px 40px; 
  position: relative;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.custom-calendar {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-event {
  padding: 5px;
  border-radius: 5px;
  font-size: 0.83rem;
}

.bg-green-500 {
  background-color: #28d247;
}

.bg-red-500 {
  background-color: #e53e3e;
}

.bg-yellow-500 {
  background-color: #f3ce56;
}

.text-white {
  color: #fff;
}

/* Ensure the DatePicker dropdown is above other elements */
.react-datepicker-popper {
  z-index: 1050 !important; /* Ensure this is higher than the event tiles */
}

/* Optionally adjust the z-index of event tiles */
.custom-event {
  z-index: 1; /* Ensure this is lower than the DatePicker */
}

.react-datepicker-wrapper input,
.react-datepicker__input-container input {
  box-shadow: none !important; 
  border: 1px solid #ccc !important; 
  border-radius: 4px !important;
  outline: none !important; 
  padding: 0; 
  background: white !important; 
  font-size: inherit; 
  color: inherit; 
}




